<template>
  <div>
    <v-card-title
      class="no-padding mb-2 font-weight-bold EoleBlue--text"
      style="font-size: 0.95rem !important; color: rgba(94, 86, 105, 0.76) !important"
      >VALIDATION DES JOURS TRAVAILLÉS</v-card-title
    >
    <v-row class="mb-n10">
      <v-col cols="12" md="2">
        <v-text-field label="Collaborateur" v-model="userFilter"> </v-text-field>
      </v-col>
      <v-col cols="12" md="2">
        <v-select
          label="Mois de l'année"
          v-model="monthFilter"
          :items="[
            {
              id: 0,
              name: 'Janvier',
            },
            {
              id: 1,
              name: 'Février',
            },
            {
              id: 2,
              name: 'Mars',
            },
            {
              id: 3,
              name: 'Avril',
            },
            {
              id: 4,
              name: 'Mai',
            },
            {
              id: 5,
              name: 'Juin',
            },
            {
              id: 6,
              name: 'Juillet',
            },
            {
              id: 7,
              name: 'Août',
            },
            {
              id: 8,
              name: 'Septembre',
            },
            {
              id: 9,
              name: 'Octobre',
            },
            {
              id: 10,
              name: 'Novembre',
            },
            {
              id: 11,
              name: 'Décembre',
            },
          ]"
          item-text="name"
          item-value="id"
          @change="
            () => {
              processLeavesForMonth(monthFilter)
              processLeaveForMonthHistory(monthFilter)
            }
          "
        >
        </v-select>
      </v-col>
      <v-col cols="12" md="8" class="text-right mt-5">
        <v-btn v-if="showInactive" icon color="EoleBlue" @click="generateExcel">
          <i class="fa-solid fa-table" style="font-size: 1.1rem"></i>
        </v-btn>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" icon color="EoleBlue" @click="showInactive = !showInactive">
              <v-icon v-if="showInactive">mdi-eye-off</v-icon>
              <v-icon v-else>mdi-history</v-icon>
            </v-btn>
          </template>
          <span v-if="!showInactive"> Afficher l'historique des jours travaillés </span>
          <span v-else> Afficher les jours travaillés non validés </span>
        </v-tooltip>
      </v-col>
    </v-row>
    <v-row v-if="collaboratorsValidation.length > 0 && !showInactive">
      <v-dialog v-model="dialogAction" right transition="slide-x-transition" close-on-content-click width="1300px">
        <v-card class="rounded-l-xl" style="box-shadow: 0 0 60px -20px #000000d9">
          <v-card-text style="background-color: #f1f4f9">
            <v-row>
              <v-col cols="12" md="3" class="no-padding ml-n2">
                <v-card class="rounded-l-xl" color="#f1f4f9" style="background-color: #f1f4f9" flat>
                  <v-card-title>
                    <v-img
                      :src="require('@/assets/images/logos/anavel_logo.png')"
                      max-height="150px"
                      max-width="120px"
                      alt="logo"
                      contain
                    ></v-img>
                  </v-card-title>
                  <v-card-title class="text-center justify-center muller-capitalized"
                    >Mes Services Assurance</v-card-title
                  >
                  <v-card-subtitle class="text-center justify-center">Un logiciel ANAVEL</v-card-subtitle>
                  <v-card-text class="no-padding">
                    <v-img :src="require('@/assets/images/logos/layer.png')" alt="logo" width="300px" class=""> </v-img>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" md="9" class="no-padding d-flex">
                <v-card class="rounded-r-xl flex-fill flex d-flex flex-column" flat>
                  <v-card-title class="muller-capitalized EoleBlue--text">
                    {{ selectedItem.firstname }} a des jours à valider ⚠️
                  </v-card-title>
                  <!-- <v-card-text>{{ selectedItem }}</v-card-text> -->
                  <v-card-subtitle> Pour le mois de : {{ getMonthName(selectedItem.month) }} </v-card-subtitle>
                  <v-card-text class="mt-5">
                    <v-textarea
                      v-model="selectedItem.manager_comment"
                      label="Commentaire manager"
                      outlined
                      dense
                    ></v-textarea>
                  </v-card-text>
                  <v-spacer> </v-spacer>
                  <v-card-actions class="text-center justify-center">
                    <v-btn color="EoleError" class="white--text" @click="postDaysWorkValidation(selectedItem, 'reject')"
                      >Refuser</v-btn
                    >
                    <v-btn
                      color="EoleGreen"
                      class="white--text"
                      @click="postDaysWorkValidation(selectedItem, 'confirm')"
                      >Accepter les jours travaillés</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-col cols="12" md="12">
        <div v-if="filteredCollabsInfo.length > 0 && processLoading">
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Nom</th>
                  <th v-for="(day, index) in getDayByMonth(monthFilter)" :key="index">{{ day.format("DD MMM") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="collaborator in filteredCollabsInfo" :key="collaborator.id">
                  <td>{{ collaborator.lastname }} {{ collaborator.firstname }}</td>
                  <td
                    v-for="(day, index) in getDayByMonth(monthFilter)"
                    :key="index"
                    :class="['custom-td-height', getLeaveClassForMonth(collaborator, day)]"
                  >
                    <v-row>
                      <v-col
                        v-if="getLeaveClassForMonth(collaborator, day) != 'working'"
                        :class="[
                          'white--text justify-center text-center',
                          getLeaveClassForMonth(collaborator, day) ? 'mb-5' : 'mt-5',
                        ]"
                        cols="12"
                        md="12"
                        style="text-align: center; padding: 10px; border-radius: 5px"
                      >
                        <div
                          v-if="getLeaveClassForMonth(collaborator, day)"
                          :class="['white--text', getHalfDayClass(day, collaborator.id)]"
                          :style="getHalf(day, collaborator.id) ? 'position: relative; top: 50px' : null"
                        >
                          {{ getHolidayInformation(day, collaborator.id) }}
                        </div>
                      </v-col>
                      <v-col
                        v-else
                        :class="[
                          'white--text justify-center text-center',
                          getLeaveClassForMonth(collaborator, day) ? 'mb-5' : 'mt-5',
                        ]"
                        cols="12"
                        md="12"
                        style="text-align: center; padding: 10px; border-radius: 5px"
                      >
                        <div class="EoleBlue--text font-weight-bold">JT</div>
                      </v-col>
                      <v-col
                        v-if="getHalf(day, collaborator.id)"
                        cols="12"
                        md="12"
                        :class="[getHalfDayClass(day, collaborator.id), 'font-weight-bold', 'EoleBlue--text']"
                        >JT
                      </v-col>
                    </v-row>

                    <!-- <v-row>
                      <v-col
                        v-if="getLeaveClassForMonth(collaborator, day) != 'working'"
                        :class="[
                          'white--text justify-center text-center',
                          getLeaveClassForMonth(collaborator, day) ? 'mb-5' : 'mt-5',
                        ]"
                        cols="12"
                        md="12"
                        style="text-align: center; padding: 10px; border-radius: 5px"
                      >
                        <div
                          v-if="getHalfDayType(day, collaborator.id) == 0"
                          :class="['white--text']"
                          :style="getHalf(day, collaborator.id) ? 'position: relative; top: 10px' : null"
                        >
                          {{ getHolidayInformation(day, collaborator.id) }}
                        </div>
                        <div
                          v-if="getHalfDayType(day, collaborator.id) == 1"
                          :class="['white--text']"
                          :style="getHalf(day, collaborator.id) ? 'position: relative; top: 10px' : null"
                        >
                          {{ getHolidayInformation(day, collaborator.id) }}
                        </div>
                        <div
                          v-else-if="getHalfDayType(day, collaborator.id) == 2"
                          :class="['white--text']"
                          :style="getHalf(day, collaborator.id) ? 'position: relative; top: 50px' : null"
                        >
                          {{ getHolidayInformation(day, collaborator.id) }}
                        </div>
                      </v-col>
                      <v-col
                        v-else
                        :class="[
                          'EoleBlue--text justify-center text-center',
                          getLeaveClassForMonth(collaborator, day) ? 'mb-0' : 'mt-0',
                        ]"
                        cols="12"
                        md="12"
                        style="text-align: center; padding: 10px; border-radius: 5px"
                      >
                        <div class="EoleBlue--text">JT PR</div>
                      </v-col>
                      <v-col
                        v-if="getHalf(day, collaborator.id)"
                        cols="12"
                        md="12"
                        :class="[getHalfDayClass(day, collaborator.id), 'EoleBlue--text']"
                        >JT ROMAN</v-col
                      >
                    </v-row> -->
                  </td>
                  <td>
                    <v-btn icon @click="openDialog(collaborator)">
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>
        <div v-else>Aucune donnée</div>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col cols="12" md="12">
        <div v-if="filteredCollabsHistoryInfo.length > 0">
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Nom</th>
                  <th v-for="day in getDayByMonth(monthFilter)" :key="day">{{ day.format("DD MMM") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="collaborator in filteredCollabsHistoryInfo" :key="collaborator.id">
                  <td class="'no-padding'">{{ collaborator.lastname }} {{ collaborator.firstname }}</td>
                  <td
                    v-for="(day, index) in getDayByMonth(monthFilter)"
                    :key="index"
                    :class="['custom-td-height', getLeaveClassForMonthHistory(collaborator, day)]"
                  >
                    <v-row>
                      <v-col
                        v-if="getLeaveClassForMonthHistory(collaborator, day) != 'working'"
                        :class="[
                          'white--text justify-center text-center',
                          getLeaveClassForMonthHistory(collaborator, day) ? 'mb-5' : 'mt-5',
                        ]"
                        cols="12"
                        md="12"
                        style="text-align: center; padding: 10px; border-radius: 5px"
                      >
                        <div
                          v-if="getLeaveClassForMonthHistory(collaborator, day)"
                          :class="['white--text']"
                          :style="getHalfForMonthHistory(day, collaborator.id) ? 'position: relative; top: 48px' : null"
                        >
                          {{ getHolidayInformationHistory(day, collaborator.id) }}
                        </div>
                      </v-col>
                      <v-col
                        v-else
                        :class="[
                          'white--text justify-center text-center',
                          getLeaveClassForMonthHistory(collaborator, day) ? 'mb-5' : 'mt-5',
                        ]"
                        cols="12"
                        md="12"
                        style="text-align: center; padding: 10px; border-radius: 5px"
                      >
                        <div class="EoleBlue--text font-weight-bold">JT</div>
                      </v-col>
                      <v-col
                        v-if="getHalfForMonthHistory(day, collaborator.id)"
                        cols="12"
                        md="12"
                        :class="[
                          getHalfDayClassForMonthHistory(day, collaborator.id),
                          'font-weight-bold',
                          'EoleBlue--text',
                        ]"
                        >JT
                      </v-col>
                    </v-row>

                    <!-- <v-row>
                      <v-col
                        v-if="getLeaveClassForMonthHistory(collaborator, day) != 'working'"
                        :class="[
                          'white--text justify-center text-center',
                          getLeaveClassForMonthHistory(collaborator, day) ? 'mb-5' : 'mt-5',
                        ]"
                        cols="12"
                        md="12"
                        style="text-align: center; padding: 10px; border-radius: 5px"
                      >
                        <div
                          v-if="getHalfDayType(day, collaborator.id) == 0"
                          :class="['white--text']"
                          :style="getHalf(day, collaborator.id) ? 'position: relative; top: 10px' : null"
                        >
                          {{ getHolidayInformationHistory(day, collaborator.id) }}
                        </div>
                        <div
                          v-if="getHalfDayType(day, collaborator.id) == 1"
                          :class="['white--text']"
                          :style="getHalf(day, collaborator.id) ? 'position: relative; top: 10px' : null"
                        >
                          {{ getHolidayInformationHistory(day, collaborator.id) }}
                        </div>
                        <div
                          v-else-if="getHalfDayType(day, collaborator.id) == 2"
                          :class="['white--text']"
                          :style="getHalf(day, collaborator.id) ? 'position: relative; top: 50px' : null"
                        >
                          {{ getHolidayInformationHistory(day, collaborator.id) }}
                        </div>
                      </v-col>
                      <v-col
                        v-else
                        :class="[
                          'EoleBlue--text justify-center text-center',
                          getLeaveClassForMonth(collaborator, day) ? 'mb-0' : 'mt-0',
                        ]"
                        cols="12"
                        md="12"
                        style="text-align: center; padding: 10px; border-radius: 5px"
                      >
                        <div class="EoleBlue--text">JT</div>
                      </v-col>
                      <v-col
                        v-if="getHalf(day, collaborator.id)"
                        cols="12"
                        md="12"
                        :class="[getHalfDayClass(day, collaborator.id), 'EoleBlue--text']"
                        >JT</v-col
                      >
                    </v-row> -->
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>
        <div v-else>Aucune donnée</div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import config from "@/views/config/config"
import json2csv from "json2csv"
import * as XLSX from "xlsx"

import moment from "moment"
import "moment-business-days"
import Holidays from "date-holidays"
import { getCookie } from "@/utils/cookies"

const hd = new Holidays()
hd.init("FR")

moment.updateLocale("fr", {
  holidays: [
    "2023-01-01",
    "2023-04-17",
    "2023-05-01",
    "2023-05-08",
    "2023-05-25",
    "2023-06-05",
    "2023-07-14",
    "2023-08-15",
    "2023-11-01",
    "2023-11-11",
    "2023-12-25",
  ],
  holidayFormat: "YYYY-MM-DD",
  workingWeekdays: [1, 2, 3, 4, 5],
})

export default {
  name: "Calendar",
  props: {
    collaboratorsHistory: {
      type: Array,
      required: true,
    },
    test: {
      type: Array,
      required: true,
    },
    collaborators: {
      type: Array,
      required: true,
    },
    demand: {
      type: Array,
      required: true,
    },
    demandHistory: {
      type: Array,
      required: true,
    },
    collaboratorsValidation: {
      type: Array,
      required: true,
    },
    collaboratorsCompensatoryLeave: {
      type: Array,
      required: true,
    },
    collaboratorsRecoveryLeave: {
      type: Array,
      required: true,
    },
    agencyInformations: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      processLoading: false,
      leavesForMonth: [],
      monthFilter: moment().month() - 1,
      managerComment: "",
      dialogAction: false,
      userFilter: "",
      showInactive: false,
      collaboratorsInLeaveToday: 0,
      dialogCollaboratorsRecoveryLeave: false,
      dialogCollaboratorsCompensatoryLeave: false,
      dialogCollaboratorsValidation: false,
      editedDay: {},
      leavesLastMonth: [],
      leavesLastMonthHistory: [],
      currentYear: moment().year(),
      lastYear: moment().year() - 1,
      nextYear: moment().year() + 1,
      itemsHoliday: [
        { id: 1, type: "COP", label: "Congés payés" },
        { id: 2, type: "RTT", label: "Récupération du temps de travail" },
        { id: 3, type: "JFC", label: "Jours forfait compensatoire" },
        { id: 4, type: "COEF", label: "Événement familial" },
        { id: 5, type: "MAL", label: "Maladie" },
        { id: 6, type: "CSS", label: "Congés sans solde" },
        { id: 7, type: "MP", label: "Maladie professionnelle" },
        { id: 8, type: "AT", label: "Accident du travail" },
        { id: 9, type: "MAT", label: "Maternité" },
        { id: 10, type: "PAT", label: "Paternité" },
        { id: 11, type: "ABNJ", label: "Absence non justifiée" },
        { id: 12, type: "REA", label: "Révision examen apprenti" },
        { id: 13, type: "TT", label: "Télétravail" },
        { id: 14, type: "ABJR", label: "Absence justifiée rémunurée" },
      ],
      comments: "",
      dialogAction: false,
      headersHolidays: [
        {
          text: "Collaborateur",
          align: "start",
          sortable: true,
          value: "name",
        },
        {
          text: "Date de la demande",
          value: "date",
        },
        {
          text: "Période souhaitée",
          value: "period",
        },
        {
          text: "Type de congés",
          value: "type",
        },
        {
          text: "Solde à date",
          value: "days_remaining",
        },
        {
          text: "Actions",
          value: "actions",
        },
      ],
      agency: "",
      year: moment().year(), // Ajout de l'année actuelle
      totalNumberWork: 0,
      month: moment().month() + 1, // Mois actuel
      leaves: [], // Données des congés
      daysOfMonth: [], // Jours du mois
      isDataLoaded: false,
      selectedItem: {
        collaborator_comment: "",
        id: 0,
        month: 0,
      },
      type: "",
      daysOfLastMonth: [],
      publicHolidays: [],
      lastMonthPublicHolidays: [],
      newCompensatoryLeaveBalance: 0,
      newRecoveryLeaveBalance: 0,
      months: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
    }
  },
  computed: {
    filteredCollabsHistoryInfo() {
      // Vérifie que `test` est défini et que `monthFilter` a une valeur valide.
      if (!this.test || this.monthFilter == null) {
        return []
      }

      // Trouver les données correspondant au mois sélectionné dans `test`
      const monthData = this.test.find(data => data.month == this.monthFilter)

      if (!monthData) {
        return []
      }

      // Filtrer et traiter les données des collaborateurs pour le mois sélectionné
      const collabsInfo = monthData.collabs_info.map(collaborator => {
        console.log("collaborator", collaborator)
        // Traiter `worked_days` pour chaque collaborateur
        const workedDaysProcessed = collaborator.worked_days_history.flatMap(workedDay => {
          // Analyser la chaîne JSON des jours travaillés/absences
          try {
            const days = JSON.parse(workedDay.days)
            // Filtrer les jours qui correspondent au type et à l'état désirés, si nécessaire
            return days.filter(day => {
              // Exemple de filtrage supplémentaire, si nécessaire
              return true // Remplacer par la condition de filtrage appropriée
            })
          } catch (error) {
            console.error("Erreur lors de l'analyse de JSON pour les jours travaillés", error)
            return []
          }
        })

        return {
          ...collaborator,
          worked_days_processed: workedDaysProcessed,
        }
      })

      // Filtrer par nom de famille et prénom si le filtre utilisateur est spécifié
      const filteredCollabs = this.userFilter
        ? collabsInfo.filter(
            collaborator =>
              collaborator.lastname.toLowerCase().includes(this.userFilter.toLowerCase()) ||
              collaborator.firstname.toLowerCase().includes(this.userFilter.toLowerCase()),
          )
        : collabsInfo

      return filteredCollabs.filter(collaborator => collaborator.worked_days_processed.length > 0)
    },
    filteredCollabsInfo() {
      // Vérifie que `test` est défini et que `monthFilter` a une valeur valide.
      if (!this.test || this.monthFilter == null) {
        return []
      }

      // Trouver les données correspondant au mois sélectionné dans `test`
      const monthData = this.test.find(data => data.month == this.monthFilter)

      if (!monthData) {
        return []
      }

      // Filtrer et traiter les données des collaborateurs pour le mois sélectionné
      const collabsInfo = monthData.collabs_info.map(collaborator => {
        // Traiter `worked_days` pour chaque collaborateur
        const workedDaysProcessed = collaborator.worked_days.flatMap(workedDay => {
          if (workedDay.days == null) return [] // Gérer le cas où `days` est null ou undefined

          // Analyser la chaîne JSON des jours travaillés/absences
          try {
            const days = JSON.parse(workedDay.days)
            // Filtrer les jours qui correspondent au type et à l'état désirés, si nécessaire
            return days.filter(day => {
              // Exemple de filtrage supplémentaire, si nécessaire
              return true // Remplacer par la condition de filtrage appropriée
            })
          } catch (error) {
            console.error("Erreur lors de l'analyse de JSON pour les jours travaillés", error)
            return []
          }
        })

        return {
          ...collaborator,
          worked_days_processed: workedDaysProcessed,
        }
      })

      // Filtrer par nom de famille et prénom si le filtre utilisateur est spécifié
      const filteredCollabs = this.userFilter
        ? collabsInfo.filter(
            collaborator =>
              collaborator.lastname.toLowerCase().includes(this.userFilter.toLowerCase()) ||
              collaborator.firstname.toLowerCase().includes(this.userFilter.toLowerCase()),
          )
        : collabsInfo

      return filteredCollabs.filter(collaborator => collaborator.worked_days_processed.length > 0)
    },

    displayMonth() {
      return moment()
        .month(this.month - 1)
        .format("MMMM")
    },
  },
  watch: {
    // month() {
    //   this.updateCalendar()
    // },
    collaborators: {
      deep: true,
      handler() {
        this.updateCalendar()
      },
    },
    collaboratorsValidation: {
      deep: true,
      handler() {
        this.updateCalendar()
      },
    },
  },
  methods: {
    async loadData() {
      // Simulez le chargement des données ici, par exemple :
      await this.processLeavesForMonth(this.monthFilter)
      await this.processLeaveForMonthHistory(this.monthFilter)
      // Après le chargement des données
      this.processLoading = true // Indiquez que le chargement est terminé
    },
    // Fonction pour générer des styles de bordure
    getBorderStyle() {
      return {
        top: { style: "thin", color: { auto: 1 } },
        right: { style: "thin", color: { auto: 1 } },
        bottom: { style: "thin", color: { auto: 1 } },
        left: { style: "thin", color: { auto: 1 } },
      }
    },

    // Fonction pour appliquer le style à une cellule spécifique
    setCellStyle(ws, cellRef, style) {
      ws[cellRef] = ws[cellRef] || {}
      ws[cellRef].s = { ...ws[cellRef].s, ...style }
    },
    generateExcel() {
      const { data, merges } = this.createExcelData()
      const wb = XLSX.utils.book_new()
      const ws = XLSX.utils.aoa_to_sheet(data)

      // Appliquer les fusions de cellules
      ws["!merges"] = merges
      // Appliquer les styles de bordure à toutes les cellules
      data.forEach((row, rowIndex) => {
        row.forEach((_, colIndex) => {
          const cellRef = XLSX.utils.encode_cell({ r: rowIndex, c: colIndex })
          this.setCellStyle(ws, cellRef, { border: this.getBorderStyle() })
        })
      })

      // Appliquer les styles (si nécessaire)
      // Assurez-vous d'appliquer des styles seulement après avoir ajouté les fusions
      data.forEach((row, rowIndex) => {
        row.forEach((cell, colIndex) => {
          if (cell !== "JT" && rowIndex > 1) {
            // Ignorer les en-têtes et la seconde ligne pour 'm' et 'am'
            const cellRef = XLSX.utils.encode_cell({ r: rowIndex, c: colIndex })
            ws[cellRef] = ws[cellRef] || {}
            ws[cellRef].s = {
              fill: { fgColor: { rgb: "FFFF00" } },
            }
          }
        })
      })

      XLSX.utils.book_append_sheet(wb, ws, "Sheet1")
      XLSX.writeFile(wb, `${this.getMonthName(this.monthFilter)}.xlsx`)
    },

    //   createExcelData() {
    //     console.log("proute", this.filteredCollabsHistoryInfo)
    //     const days = this.getDayByMonth(this.monthFilter) // Suppose que cela retourne un tableau de moments/dates

    // // Étape 1: Identifier tous les types d'absences uniques
    // const uniqueTypes = new Set();
    // collaborators.forEach(collab => {
    //   collab.worked_days_processed.forEach(day => {
    //     uniqueTypes.add(day.type); // Ajouter le type d'absence au Set
    //   });
    // });

    // // Initialiser les en-têtes avec les colonnes de base
    // const headers = ["Nom", "Affilié mutuelle ?", "Télétravail ?"];
    // // Ajouter les en-têtes pour chaque type d'absence
    // uniqueTypes.forEach(type => headers.push(`Cumul ${type}`));
    //       const merges = [] // Configuration des fusions pour les en-têtes de jour

    //     // Ajouter les en-têtes de jour avec "AM" et "PM" en dessous
    //     days.forEach((day, index) => {
    //       const formattedDay = day.format("DD MMM")
    //       headers.push(formattedDay) // Ajouter l'en-tête de jour
    //       // Ajouter la configuration de fusion pour l'en-tête de jour
    //       const baseIndex = 3 + index * 2 // 3 pour les colonnes 'Nom', 'Affilié mutuelle ?', 'Télétravail ?'
    //       merges.push({ s: { r: 0, c: baseIndex }, e: { r: 0, c: baseIndex + 1 } })
    //       // On ajoute également une cellule vide après chaque en-tête de jour pour la fusion
    //       headers.push("") // Cellule vide pour "PM"
    //     })

    //     // Créez une seconde ligne avec 'm' et 'am' pour chaque jour
    //     // La seconde ligne commence avec trois cellules vides pour les trois premières colonnes
    //     const secondRow = new Array(3).fill(null).concat(days.flatMap(() => ["m", "am"]))

    //     const data = [headers, secondRow]

    //     this.filteredCollabsHistoryInfo.forEach(collaborator => {
    //       const rowData = [
    //         `${collaborator.lastname} ${collaborator.firstname}`,
    //         collaborator.is_affiliated_mutual == 1 ? "oui" : "non",
    //         collaborator.is_telework == 1 ? "oui" : "non",
    //       ]

    //       // Insérez ici le code pour traiter les jours travaillés et les absences
    //       days.forEach(day => {
    //         let amData = "JT" // Par défaut, journée travaillée
    //         let pmData = "JT" // Par défaut, journée travaillée

    //         collaborator.worked_days_processed.forEach(absence => {
    //           const absenceStartDate = new Date(absence.start_date)
    //           const absenceEndDate = new Date(absence.end_date)
    //           const currentDay = new Date(day.format("YYYY-MM-DD"))

    //           if (currentDay >= absenceStartDate && currentDay <= absenceEndDate) {
    //             if (absence.is_half_day === 1) {
    //               if (absence.half_day_type === 1) {
    //                 amData = absence.type
    //               } else if (absence.half_day_type === 2) {
    //                 pmData = absence.type
    //               }
    //             } else {
    //               amData = absence.type
    //               pmData = absence.type
    //             }
    //           }
    //         })

    //         rowData.push(amData, pmData)
    //       })

    //       data.push(rowData)
    //     })

    //     // Ajout d'une ligne vide pour séparer les tableaux
    //     data.push([""])
    //     data.push([""])
    //     data.push([""])
    //     data.push([""])
    //     data.push([""])

    //     // Ajout des en-têtes pour le tableau des types de congés
    //     data.push(["Abbréviation", "Type de congé"])

    //     // Parcourir itemsHoliday pour remplir les données des types de congés
    //     this.itemsHoliday.forEach(holiday => {
    //       data.push([holiday.type, holiday.label])
    //     })

    //     // Ajoutez d'autres lignes ou sections si nécessaire

    //     return { data, merges }
    //   },

    createExcelData() {
      console.log("proute", this.filteredCollabsHistoryInfo)
      const days = this.getDayByMonth(this.monthFilter) // Suppose que cela retourne un tableau de moments/dates

      // Étape 1: Identifier tous les types d'absences uniques
      const uniqueTypes = new Set()
      this.filteredCollabsHistoryInfo.forEach(collab => {
        collab.worked_days_processed.forEach(day => {
          console.log(day)
          if (Object.keys(day).length === 0) return

          uniqueTypes.add(day.type) // Ajouter le type d'absence au Set
        })
      })

      // Initialiser les en-têtes avec les colonnes de base
      const headers = ["Nom", "Affilié mutuelle ?", "Télétravail ?"]
      const merges = [] // Configuration des fusions pour les en-têtes de jour

      // Ajouter les en-têtes de jour avec "AM" et "PM" en dessous
      days.forEach((day, index) => {
        const formattedDay = day.format("DD MMM")
        headers.push(formattedDay) // Ajouter l'en-tête de jour
        const baseIndex = 3 + index * 2
        merges.push({ s: { r: 0, c: baseIndex }, e: { r: 0, c: baseIndex + 1 } })
        headers.push("") // Cellule vide pour "PM"
      })

      // Ajouter les en-têtes pour chaque type d'absence après les jours
      uniqueTypes.forEach(type => headers.push(`Cumul ${type}`))
      console.log(uniqueTypes)

      const secondRow = new Array(3 + days.length * 2).fill(null) // Ajustement pour inclure les colonnes de cumul
      const data = [headers, secondRow]

      this.filteredCollabsHistoryInfo.forEach(collaborator => {
        const cumuls = {}
        uniqueTypes.forEach(type => (cumuls[type] = 0)) // Initialiser les cumuls pour chaque type

        const rowData = [
          `${collaborator.lastname} ${collaborator.firstname}`,
          collaborator.is_affiliated_mutual == 1 ? "oui" : "non",
          collaborator.is_telework == 1 ? "oui" : "non",
        ]

        days.forEach(day => {
          let amData = "JT" // Par défaut, journée travaillée
          let pmData = "JT" // Par défaut, journée travaillée

          collaborator.worked_days_processed.forEach(absence => {
            const absenceStartDate = new Date(absence.start_date)
            const absenceEndDate = new Date(absence.end_date)
            const currentDay = new Date(day.format("YYYY-MM-DD"))

            if (currentDay >= absenceStartDate && currentDay <= absenceEndDate) {
              const type = absence.type
              if (absence.is_half_day === 1) {
                const halfDayIncrement = 0.5
                if (absence.half_day_type === 1) {
                  // Matin
                  amData = type
                  cumuls[type] = (cumuls[type] || 0) + halfDayIncrement
                } else if (absence.half_day_type === 2) {
                  // Après-midi
                  pmData = type
                  cumuls[type] = (cumuls[type] || 0) + halfDayIncrement
                }
              } else {
                // Compter comme une journée complète
                amData = type
                pmData = type
                cumuls[type] = (cumuls[type] || 0) + 1 // Ajouter 1 pour une journée complète
              }
            }
          })
          rowData.push(amData, pmData)
        })

        // Ajouter les cumuls à rowData
        uniqueTypes.forEach(type => rowData.push(cumuls[type] || 0))

        data.push(rowData)
      })

      // Ajout d'une ligne vide pour séparer les tableaux
      data.push([""])
      data.push([""])
      data.push([""])
      data.push([""])
      data.push([""])

      // Ajout des en-têtes pour le tableau des types de congés
      data.push(["Abbréviation", "Type de congé"])

      // Parcourir itemsHoliday pour remplir les données des types de congés
      this.itemsHoliday.forEach(holiday => {
        data.push([holiday.type, holiday.label])
      })

      // Ajoutez d'autres lignes ou sections si nécessaire

      return { data, merges }
      // Continuez avec le reste de votre logique...
    },

    processLeaveForMonthHistory(targetMonth) {
      this.leavesLastMonthHistory = this.collaboratorsValidation.flatMap(collaborator => {
        if (!collaborator.worked_days_history || collaborator.worked_days_history.length == 0) {
          return [
            {
              collaboratorId: collaborator.id,
              startDate: null,
              endDate: null,
              days: null,
              state: null,
              isHalfDay: null,
              halfDayType: null,
              type: null,
              idWorkingDay: null,
            },
          ]
        }
        return collaborator.worked_days_history.flatMap(workedDay => {
          workedDay == null ? [] : workedDay.days

          let daysArray = typeof workedDay.days == "string" ? JSON.parse(workedDay.days) : workedDay.days

          if (!Array.isArray(daysArray)) {
            return []
          }

          return daysArray
            .filter(holiday => {
              let startDate = moment(holiday.start_date)
              let endDate = moment(holiday.end_date)
              return startDate.month() == targetMonth || endDate.month() == targetMonth
            })
            .map(holiday => ({
              collaboratorId: collaborator.id,
              startDate: moment(holiday.start_date).format("YYYY-MM-DD"),
              endDate: moment(holiday.end_date).format("YYYY-MM-DD"),
              days: holiday.number_of_days,
              state: holiday.state,
              isHalfDay: holiday.is_half_day,
              halfDayType: holiday.half_day_type,
              type: holiday.type,
              idWorkingDay: workedDay.id,
            }))
        })
      })
    },
    processLeavesForMonth(targetMonth) {
      // targetMonth est basé sur 0, donc Janvier = 0, Février = 1, etc.
      this.leavesForMonth = this.collaboratorsValidation.flatMap(collaborator => {
        // Vérifier si le collaborateur a des jours travaillés
        if (!collaborator.worked_days || collaborator.worked_days.length == 0) {
          // Si le collaborateur n'a pas de jours travaillés, retourner un objet avec ses informations
          return [
            {
              collaboratorId: collaborator.id,
              startDate: null,
              endDate: null,
              days: null,
              state: null,
              isHalfDay: null,
              halfDayType: null,
              type: null,
              idWorkingDay: null,
            },
          ]
        }
        // S'il y a des jours travaillés pour le collaborateur, effectuer le traitement habituel
        return collaborator.worked_days.flatMap(workedDay => {
          // Convertir la chaîne JSON en tableau d'objets, si elle n'est pas déjà un objet
          workedDay == null ? [] : workedDay.days
          let daysArray = typeof workedDay.days == "string" ? JSON.parse(workedDay.days) : workedDay.days

          // Assurez-vous que daysArray est un tableau valide
          if (!Array.isArray(daysArray)) {
            return [] // Retourner un tableau vide si daysArray n'est pas un tableau
          }

          // Filtrer et mapper les jours d'absence pour le mois ciblé
          return daysArray
            .filter(holiday => {
              let startDate = moment(holiday.start_date)
              let endDate = moment(holiday.end_date)
              return startDate.month() == targetMonth || endDate.month() == targetMonth
            })
            .map(holiday => ({
              collaboratorId: collaborator.id,
              startDate: moment(holiday.start_date).format("YYYY-MM-DD"),
              endDate: moment(holiday.end_date).format("YYYY-MM-DD"),
              days: holiday.number_of_days,
              state: holiday.state,
              isHalfDay: holiday.is_half_day,
              halfDayType: holiday.half_day_type,
              type: holiday.type,
              idWorkingDay: workedDay.id,
            }))
        })
      })
    },
    getDaysInMonth(month) {
      return Array.from({ length: moment().month(month).daysInMonth() }, (_, i) =>
        moment()
          .month(month)
          .date(i + 1),
      )
    },
    getMonthName(month) {
      return moment().month(month).format("MMMM")
    },
    getClassForDay(collaborator, day) {
      // Utilisez la logique de `getLeaveClassLastMonth` adaptée pour `getClassForDay`
      const leave = this.leavesLastMonth.find(
        leave => leave.collaboratorId == collaborator.id && moment(day.date).isSame(leave.startDate, "day"),
      )

      if (!leave) return "working" // Aucun congé, jour travaillé

      // Adaptation des conditions pour getClassForDay
      if (leave.isHalfDay) {
        if (leave.halfDayType == 1) {
          return leave.state == 3 ? "leave-half-morning-validated" : "leave-half-morning-validated"
        } else if (leave.halfDayType == 2) {
          return leave.state == 3 ? "leave-half-afternoon-validated" : "leave-half-afternoon-validated"
        }
      } else {
        if (moment(day.date).isSame(leave.startDate, "day") || moment(day.date).isSame(leave.endDate, "day")) {
          return leave.state == 3 ? "leave-day-validated" : "leave-day-pending"
        }
      }
    },
    getInfoForDay(collaborator, day) {
      // Utilisez une logique similaire à `getLeaveClassLastMonth` pour déterminer l'info
      const leave = this.leavesLastMonth.find(
        leave =>
          leave.collaboratorId == collaborator.id &&
          moment(day.date).isBetween(leave.startDate, leave.endDate, "day", "[]"),
      )

      if (!leave) return "JT" // Jour Travaillé par défaut

      // Conditions adaptées pour getInfoForDay
      if (leave.isHalfDay) {
        return leave.halfDayType == 1 ? "Matinée" : "Après-midi"
      } else {
        return "Congé"
      }
    },

    range(start, end) {
      return Array.from({ length: end - start + 1 }, (_, i) => i + start)
    },
    getDayOfMonth(month) {
      console.log("proutde")
      return this.range(
        1,
        moment()
          .month(month - 1)
          .daysInMonth(),
      ).map(day =>
        moment()
          .month(month - 1)
          .date(day),
      )
    },
    getDayByMonth(month) {
      const year = moment().year() // Utilisez l'année actuelle ou une autre logique pour l'année
      const daysInMonth = moment([year, month]).daysInMonth()
      let days = []

      for (let day = 1; day <= daysInMonth; day++) {
        const currentDate = moment([year, month]).date(day)
        // Vérifiez si c'est un jour ouvrable et pas un jour férié
        if (currentDate.isBusinessDay() && !this.publicHolidays.includes(currentDate.format("YYYY-MM-DD"))) {
          days.push(currentDate)
        }
      }

      return days
    },
    getMonth() {
      return moment()
        .month(this.month - 1)
        .format("MMMM")
    },
    collaboratorsValidationFilter(collaborator) {
      return collaborator.filter(collaborator => {
        return (
          collaborator.lastname.toLowerCase().includes(this.userFilter.toLowerCase()) ||
          collaborator.firstname.toLowerCase().includes(this.userFilter.toLowerCase())
        )
      })
    },
    postNewRecoveryLeave(balance) {
      const idAgency = this.$store.state.user.agency_id
      try {
        const formData = new FormData()

        formData.append("place", "setNewRecoveryLeaveBalance")
        formData.append("idAgency", idAgency)
        formData.append("balance", balance)

        fetch(`${config.apiUri}/agencies/${idAgency}/holidays`, {
          method: "POST",
          body: formData,
        }).then(response => {
          if (response.ok) {
            this.$toast.success(`Vous configuré le nombre de jours de RTT sur : ${balance}`, {
              position: "bottom-right",
            })

            this.$emit("update")

            this.$forceUpdate()

            this.dialogCollaboratorsRecoveryLeave = false
          } else {
            this.$toast.error("Erreur", {
              position: "bottom-right",
            })
          }
        })
      } catch (err) {
        console.log(err)
      }
    },
    verifyCollaboratorIsInLeaveToday() {
      const today = moment().format("YYYY-MM-DD")
      let length = 0

      this.leaves.forEach(leave => {
        if (moment(today).isBetween(leave.startDate, leave.endDate, "day", "[]") && leave.state == 3) {
          length++
          console.log(leave)
        }
      })

      return length
    },
    postNewCompensatoryLeave(balance) {
      const idAgency = this.$store.state.user.agency_id
      try {
        const formData = new FormData()

        formData.append("place", "setNewCompensatoryLeaveBalance")
        formData.append("idAgency", idAgency)
        formData.append("balance", balance)

        fetch(`${config.apiUri}/agencies/${idAgency}/holidays`, {
          method: "POST",
          body: formData,
        }).then(response => {
          if (response.ok) {
            this.$toast.success(`Vous configuré le nombre de jours forfait sur : ${balance}`, {
              position: "bottom-right",
            })

            this.$emit("update")

            this.$forceUpdate()

            this.dialogCollaboratorsCompensatoryLeave = false
          } else {
            this.$toast.error("Erreur", {
              position: "bottom-right",
            })
          }
        })
      } catch (err) {
        console.log(err)
      }
    },
    getLastMonthPublicHolidaysFrance(year) {
      this.lastMonthPublicHolidays = []

      const frenchHolidays = hd.getHolidays(year)

      frenchHolidays.forEach(holiday => {
        holiday.date = moment(holiday.date).format("YYYY-MM-DD")

        this.lastMonthPublicHolidays.push(holiday.date)
      })
    },
    getPublicHolidaysFrance(year) {
      this.publicHolidays = []

      const frenchHolidays = hd.getHolidays(year)

      frenchHolidays.forEach(holiday => {
        holiday.date = moment(holiday.date).format("YYYY-MM-DD")

        this.publicHolidays.push(holiday.date)
      })
    },
    postRefuseAccountDaysWorkValidation(selectedItem) {
      this.getWorkingId(selectedItem.id)

      console.log(this.getWorkingId(selectedItem.id))

      const idAgency = this.$store.state.user.agency_id
      try {
        const formData = new FormData()

        formData.append("place", "postRefuseDaysWorkValidation")
        formData.append("idAccount", selectedItem.id)
        formData.append("idWorkingDay", selectedItem.worked_days[0].id)
        formData.append("comment", this.selectedItem.manager_comment)

        fetch(`${config.apiUri}/agencies/${idAgency}/holidays`, {
          method: "POST",
          body: formData,
        }).then(response => {
          if (response.ok) {
            this.$toast.success("Vous avez modifié la demande de congés", {
              position: "bottom-right",
            })

            this.$emit("update")

            this.dialogCollaboratorsValidation = false
            this.dialogAction = false
          } else {
            this.$toast.error("Erreur", {
              position: "bottom-right",
            })
          }
        })
      } catch (err) {
        console.log(err)
      }
    },
    postDaysWorkValidation(selectedItem, action) {
      this.getWorkingId(selectedItem.id)

      try {
        const putData = {
          action: action,
          comment: this.selectedItem.manager_comment,
        }

        fetch(`${config.apiUri}/accounts/${selectedItem.id}/day-works/${selectedItem.worked_days[0].id}`, {
          method: "PUT",
          body: JSON.stringify(putData),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getCookie("jwt")}`,
          },
        }).then(response => {
          if (response.ok) {
            this.$toast.success("Vous avez modifié la demande de congés", {
              position: "bottom-right",
            })

            this.$emit("update")

            this.dialogCollaboratorsValidation = false
            this.dialogAction = false
          } else {
            this.$toast.error("Erreur", {
              position: "bottom-right",
            })
          }
        })
      } catch (err) {
        console.log(err)
      }
    },
    getHalfDayType(day, collaboratorId) {
      const leave = this.leavesForMonth.find(
        leave =>
          leave.collaboratorId == collaboratorId && moment(day).isBetween(leave.startDate, leave.endDate, "day", "[]"),
      )
      if (leave && leave.isHalfDay) {
        return leave.halfDayType // 0: Pas un demi-jour, 1: Matin, 2: Après-midi
      }
      return 0 // Pas un demi-jour
    },

    getHalfForMonthHistory(day, collaboratorId) {
      const leave = this.leavesLastMonthHistory.find(
        leave =>
          leave.collaboratorId == collaboratorId && moment(day).isBetween(leave.startDate, leave.endDate, "day", "[]"),
      )
      if (!leave) return false

      return leave.isHalfDay
    },

    getHalf(day, collaboratorId) {
      const leave = this.leavesForMonth.find(
        leave =>
          leave.collaboratorId == collaboratorId && moment(day).isBetween(leave.startDate, leave.endDate, "day", "[]"),
      )
      if (!leave) return false

      return leave.isHalfDay
    },
    getWorkingId(id) {
      const leave = this.leavesLastMonth.find(leave => leave.collaboratorId == id)

      this.editedDay = leave
    },
    // getHalfDayType(day, collaboratorId) {
    //   const leave = this.leavesLastMonth.find(
    //     leave =>
    //       leave.collaboratorId == collaboratorId && moment(day).isBetween(leave.startDate, leave.endDate, "day", "[]"),
    //   )

    //   if (!leave) return false

    //   return leave.halfDayType
    // },

    // getHolidayInformation(day, id) {
    // const leave = this.leavesLastMonth.find(leave =>
    //   moment(day).isBetween(leave.startDate, leave.endDate, "day", "[]"),
    // )
    // if (!leave) {
    //   return "working"
    // }

    // return leave.type

    //   const leave = this.leavesLastMonth.find(
    //     leave => leave.collaboratorId == id && moment(day).isBetween(leave.startDate, leave.endDate, "day", "[]"),
    //   )

    //   if (!leave) {
    //     return "working"
    //   }

    //   return leave.type
    // },
    getHolidayTypeLastMonth(collaborator, day) {
      const leave = this.leavesLastMonth.find(
        leave =>
          leave.collaboratorId == collaborator.id && moment(day).isBetween(leave.startDate, leave.endDate, "day", "[]"),
      )

      return leave.type
    },
    getHolidayType(collaborator, day) {
      const leave = this.leaves.find(
        leave =>
          leave.collaboratorId == collaborator.id && moment(day).isBetween(leave.startDate, leave.endDate, "day", "[]"),
      )

      return leave.type
    },
    openDialog(item) {
      // Méthode pour ouvrir le v-dialog pour un élément donné
      this.selectedItem = item
      this.selectedItem.month = this.monthFilter
      this.dialogAction = true
    },
    async putHoliday(id, answer) {
      const idAgency = this.$store.state.user.agency_id
      try {
        const formData = new FormData()

        formData.append("idHoliday", id)
        answer == 2 ? formData.append("place", "refuse") : formData.append("place", "accept")
        formData.append("comments", this.comments)

        fetch(`${config.apiUri}/agencies/${idAgency}/holidays`, {
          method: "POST",
          body: formData,
        }).then(response => {
          if (response.ok) {
            this.$toast.success("Vous avez modifié la demande de congés", {
              position: "bottom-right",
            })

            this.$emit("update")

            this.dialogAction = false

            this.comments = ""

            this.collaboratorsInLeaveToday = this.verifyCollaboratorIsInLeaveToday()
          } else {
            this.$toast.error("Erreur", {
              position: "bottom-right",
            })
          }
        })
      } catch (err) {
        console.log(err)
      }
    },
    previousMonth() {
      if (this.month == 1) {
        // Si le mois actuel est janvier, passez à décembre de l'année précédente
        this.month = 12
        this.year -= 1
      } else {
        this.month -= 1
      }
      this.updateCalendar()
    },
    nextMonth() {
      if (this.month == 12) {
        // Si le mois actuel est décembre, passez à janvier de l'année suivante
        this.month = 1
        this.year += 1
      } else {
        this.month += 1
      }
      this.updateCalendar()
    },

    updateCalendar() {
      this.generateDaysOfMonth()
      this.generateDaysOfLastMonth()
      this.processLeavesForMonth(this.monthFilter)
      this.processLeaveForMonthHistory(this.monthFilter)
      // this.processLeaves()
      this.isDataLoaded = true
    },
    generateDaysOfMonth() {
      this.getPublicHolidaysFrance(this.year)
      this.totalNumberWork = 0
      this.daysOfMonth = []
      let startOfMonth = moment([this.year, this.month - 1]).startOf("month")
      let endOfMonth = moment([this.year, this.month - 1]).endOf("month")

      while (startOfMonth.isSameOrBefore(endOfMonth)) {
        if (startOfMonth.isBusinessDay() && !this.publicHolidays.includes(startOfMonth.format("YYYY-MM-DD"))) {
          this.daysOfMonth.push(startOfMonth.clone())
          this.totalNumberWork++
        }
        startOfMonth.add(1, "days")
      }
    },
    generateDaysOfLastMonth() {
      this.getLastMonthPublicHolidaysFrance(this.year)
      this.daysOfLastMonth = []
      if (this.month == 1) {
        this.month = 12
        this.year -= 1
      } else {
        this.month -= 1
      }

      let lastMonth = this.month - 1

      let startOfMonth = moment([this.year, lastMonth]).startOf("month")
      let endOfMonth = moment([this.year, lastMonth]).endOf("month")

      while (startOfMonth.isSameOrBefore(endOfMonth)) {
        if (startOfMonth.isBusinessDay() && !this.lastMonthPublicHolidays.includes(startOfMonth.format("YYYY-MM-DD"))) {
          this.daysOfLastMonth.push(startOfMonth.clone())
        }
        startOfMonth.add(1, "days")
      }
    },
    processLeaves() {
      this.leaves = this.collaborators.flatMap(collaborator =>
        collaborator.holidays
          .filter(holiday => {
            let startDate = moment(holiday.start_date)
            let endDate = moment(holiday.end_date)

            return startDate.month() == this.month - 1 || endDate.month() == this.month - 1
          })
          .map(holiday => ({
            collaboratorId: collaborator.id,
            startDate: moment(holiday.start_date).format("YYYY-MM-DD"),
            endDate: moment(holiday.end_date).format("YYYY-MM-DD"),
            days: holiday.number_of_days,
            state: holiday.state,
            isHalfDay: holiday.is_half_day,
            halfDayType: holiday.half_day_type,
            type: holiday.type,
          })),
      )
    },
    processLeavesLastMonth() {
      this.leavesLastMonth = this.collaboratorsValidation.flatMap(collaborator =>
        collaborator.worked_days.flatMap(workedDay => {
          // Convertir la chaîne JSON en tableau d'objets
          let daysArray = workedDay.days

          // Filtrer et mapper les jours travaillés
          return daysArray
            .filter(holiday => {
              let startDate = moment(holiday.start_date)
              let endDate = moment(holiday.end_date)

              return startDate.month() == this.month - 1 || endDate.month() == this.month - 1
            })
            .map(holiday => ({
              collaboratorId: collaborator.id,
              startDate: moment(holiday.start_date).format("YYYY-MM-DD"),
              endDate: moment(holiday.end_date).format("YYYY-MM-DD"),
              days: holiday.number_of_days,
              state: holiday.state,
              isHalfDay: holiday.is_half_day,
              halfDayType: holiday.half_day_type,
              type: holiday.type,
              idWorkingDay: holiday.idWorkingDay,
            }))
        }),
      )
    },
    getHalfDayClassForMonthHistory(day, collaboratorId) {
      const leave = this.leavesLastMonthHistory.find(
        leave =>
          leave.collaboratorId == collaboratorId && moment(day).isBetween(leave.startDate, leave.endDate, "day", "[]"),
      )

      if (!leave) return ""

      if (leave.halfDayType == 2) {
        return "morning"
      } else if (leave.halfDayType == 1) {
        return "afternoon"
      }

      return ""
    },
    getHalfDayClass(day, collaboratorId) {
      const leave = this.leavesForMonth.find(
        leave =>
          leave.collaboratorId == collaboratorId && moment(day).isBetween(leave.startDate, leave.endDate, "day", "[]"),
      )

      if (!leave) return ""

      if (leave.halfDayType == 2) {
        return "morning"
      } else if (leave.halfDayType == 1) {
        return "afternoon"
      }

      return ""
    },
    // getHalf(day, collaboratorId) {
    //   const leave = this.leavesLastMonth.find(
    //     leave =>
    //       leave.collaboratorId == collaboratorId && moment(day).isBetween(leave.startDate, leave.endDate, "day", "[]"),
    //   )

    //   if (!leave) return false

    //   return leave.isHalfDay
    // },
    getHolidayInformationHistory(day, id) {
      const leave = this.leavesLastMonthHistory.find(
        leave => leave.collaboratorId == id && moment(day).isBetween(leave.startDate, leave.endDate, "day", "[]"),
      )

      if (!leave) {
        return "JT" // Ou toute autre valeur par défaut représentant l'absence d'absence.
      }

      return leave.type // Ou tout autre formatage ou traitement nécessaire des données d'absence.
    },
    getHolidayInformation(day, id) {
      const leave = this.leavesForMonth.find(
        leave => leave.collaboratorId == id && moment(day).isBetween(leave.startDate, leave.endDate, "day", "[]"),
      )

      if (!leave) {
        return "JT" // Ou toute autre valeur par défaut représentant l'absence d'absence.
      }

      return leave.type // Ou tout autre formatage ou traitement nécessaire des données d'absence.
    },
    getLeaveClassForMonthHistory(collaborator, day) {
      const leave = this.leavesLastMonthHistory.find(
        leave =>
          leave.collaboratorId == collaborator.id && moment(day).isBetween(leave.startDate, leave.endDate, "day", "[]"),
      )

      if (leave) {
        this.days = leave.days

        if (leave.isHalfDay == 1) {
          if (leave.halfDayType == 1) {
            return leave.state == 3 ? "leave-half-morning-validated" : "leave-half-morning-validated"
          }

          if (leave.halfDayType == 2) {
            return leave.state == 3 ? "leave-half-afternoon-validated" : "leave-half-afternoon-validated"
          }
        }

        if (moment(day).isSame(leave.startDate, "day") && leave.state == 1) {
          return "leave-start"
        }
        if (moment(day).isSame(leave.endDate, "day") && leave.state == 1) {
          return "leave-end"
        }
        if (moment(day).isSame(leave.startDate, "day") && leave.state == 3) {
          return "leave-start"
        }
        if (moment(day).isSame(leave.endDate, "day") && leave.state == 3) {
          return "leave-end"
        }
        return leave.state == 3 ? "leave-middle" : "leave-middle"
      }
      return "working"
    },
    getLeaveClassForMonth(collaborator, day) {
      const leave = this.leavesForMonth.find(
        leave =>
          leave.collaboratorId == collaborator.id && moment(day).isBetween(leave.startDate, leave.endDate, "day", "[]"),
      )

      if (leave) {
        this.days = leave.days

        if (leave.isHalfDay == 1) {
          if (leave.halfDayType == 1) {
            return leave.state == 3 ? "leave-half-morning-validated" : "leave-half-morning-validated"
          }

          if (leave.halfDayType == 2) {
            return leave.state == 3 ? "leave-half-afternoon-validated" : "leave-half-afternoon-validated"
          }
        }

        if (moment(day).isSame(leave.startDate, "day") && leave.state == 1) {
          return "leave-start"
        }
        if (moment(day).isSame(leave.endDate, "day") && leave.state == 1) {
          return "leave-end"
        }
        if (moment(day).isSame(leave.startDate, "day") && leave.state == 3) {
          return "leave-start"
        }
        if (moment(day).isSame(leave.endDate, "day") && leave.state == 3) {
          return "leave-end"
        }
        return leave.state == 3 ? "leave-middle" : "leave-middle"
      }

      return "working"
    },

    getLeaveClassLastMonth(collaborator, day) {
      const leave = this.leavesLastMonth.find(
        leave =>
          leave.collaboratorId == collaborator.id && moment(day).isBetween(leave.startDate, leave.endDate, "day", "[]"),
      )

      if (leave) {
        this.days = leave.days

        // Si c'est un demi-jour le matin

        if (leave.isHalfDay == 1) {
          if (leave.halfDayType == 1) {
            if (leave.state == 3) {
              return "leave-half-morning-validated"
            } else {
              return "leave-half-morning-validated"
            }
          }

          // Si c'est un demi-jour l'après-midi
          if (leave.halfDayType == 2) {
            if (leave.state == 3) {
              return "leave-half-afternoon-validated"
            } else {
              return "leave-half-afternoon-validated"
            }
          }
        }

        // Sinon, retournez les classes normales en fonction de 'state'
        if (moment(day).isSame(leave.startDate, "day") && leave.state == 1) {
          return "leave-start-nok"
        }
        if (moment(day).isSame(leave.endDate, "day") && leave.state == 1) {
          return "leave-end-nok"
        }
        if (moment(day).isSame(leave.startDate, "day") && leave.state == 3) {
          return "leave-start"
        }
        if (moment(day).isSame(leave.endDate, "day") && leave.state == 3) {
          return "leave-end"
        }
        if (leave.state == 3) {
          return "leave-middle"
        }
        if (leave.state == 1) {
          return "leave-middle"
        }
      }
      if (!leave) return "working"
    },

    getLeaveClass(collaborator, day) {
      const leave = this.leaves.find(
        leave =>
          leave.collaboratorId == collaborator.id && moment(day).isBetween(leave.startDate, leave.endDate, "day", "[]"),
      )

      if (leave) {
        this.days = leave.days

        // Si c'est un demi-jour le matin

        if (leave.isHalfDay == 1) {
          if (leave.halfDayType == 1) {
            if (leave.state == 3) {
              return "leave-half-morning-validated"
            } else {
              return "leave-half-morning-validated"
            }
          }

          // Si c'est un demi-jour l'après-midi
          if (leave.halfDayType == 2) {
            if (leave.state == 3) {
              return "leave-half-afternoon-validated"
            } else {
              return "leave-half-afternoon-validated"
            }
          }
        }

        // Sinon, retournez les classes normales en fonction de 'state'
        if (moment(day).isSame(leave.startDate, "day") && leave.state == 1) {
          return "leave-start-nok"
        }
        if (moment(day).isSame(leave.endDate, "day") && leave.state == 1) {
          return "leave-end-nok"
        }
        if (moment(day).isSame(leave.startDate, "day") && leave.state == 3) {
          return "leave-start"
        }
        if (moment(day).isSame(leave.endDate, "day") && leave.state == 3) {
          return "leave-end"
        }
        if (leave.state == 3) {
          return "leave-middle"
        }
        if (leave.state == 1) {
          return "leave-middle"
        }
      }
      return ""
    },
  },
  mounted() {
    this.loadData() // Appelez une méthode pour charger vos données
  },
  created() {
    this.updateCalendar()

    this.agency = localStorage.getItem("agency")

    this.generateDaysOfLastMonth()
    this.processLeaveForMonthHistory(this.monthFilter)
    this.processLeavesForMonth(this.monthFilter)

    this.collaboratorsInLeaveToday = this.verifyCollaboratorIsInLeaveToday()
  },
}
</script>

<style>
.pointer {
  cursor: pointer;
}
.leave-start-training {
  background-color: #8a8d93;
  border-radius: 5px;
  border-right: none;
}

.leave-middle-training {
  background-color: #8a8d93;
  border-radius: 5px;
  border-left: none;
  border-right: none;
}

.leave-end-training {
  background-color: #8a8d93;
  border-radius: 5px;
  border-left: none;
}

.leave-half-training {
  background-color: #8a8d93;
  border-radius: 5px;
  border-left: none;
  border-right: none;
}

.leave-start-training .row .col-12 {
  padding-top: 0px;
}

.leave-middle-training .row .col-12 {
  padding-top: 0px;
}

.leave-end-training .row .col-12 {
  padding-top: 0px;
}

.morning {
  position: relative;
  bottom: 40px !important;
}
.afternoon {
  position: relative;
  top: 0px !important;
}
.custom-td-height {
  height: 72px !important;
  width: 50px !important;
}
/* Style pour la moitié supérieure d'une cellule de congé demi-journée le matin - Validé */
.leave-half-morning-validated {
  background: linear-gradient(to bottom, #001f47 50%, transparent 50%);
  content: "AM";
}

.leave-half-morning-validated .row {
  padding-bottom: 0px !important;
}

/* Style pour la moitié supérieure d'une cellule de congé demi-journée le matin - En attente */
.leave-half-morning-pending {
  background: linear-gradient(to bottom, #fcc03c 50%, transparent 50%);
  content: "AM";
}

.leave-half-morning-pending .row {
  padding-bottom: 0px;
}

/* Style pour la moitié inférieure d'une cellule de congé demi-journée l'après-midi - Validé */
.leave-half-afternoon-validated {
  background: linear-gradient(to top, #001f47 50%, transparent 50%);
}

.leave-half-afternoon-validated .row .col-12 {
  padding-top: 0px !important;
}

.leave-half-morning-training {
  background: linear-gradient(to bottom, #8a8d93 50%, transparent 50%);
  content: "AM";
}

.leave-half-morning-training .row {
  padding-bottom: 80px;
}

.leave-half-afternoon-training {
  background: linear-gradient(to top, #8a8d93 50%, transparent 50%);
}

.leave-half-afternoon-training .row .col-12 {
  padding-top: 15px;
}

/* Style pour la moitié inférieure d'une cellule de congé demi-journée l'après-midi - En attente */
.leave-half-afternoon-pending {
  background: linear-gradient(to top, #fcc03c 50%, transparent 50%);
  content: "PM";
}

.leave-half-afternoon-pending .row {
  padding-top: 0px;
}

.leave-start-nok .row .col-12 {
  padding-top: 0px;
}

.leave-middle-nok .row .col-12 {
  padding-top: 0px;
}

.leave-end-nok .row .col-12 {
  padding-top: 0px;
}

.leave-start .row .col-12 {
  padding-top: 0px;
}

.leave-middle .row .col-12 {
  padding-top: 0px;
}

.leave-end .row .col-12 {
  padding-top: 0px;
}

.leave-half-morning-pending .row .col-12 {
  padding-top: 0px;
  margin-top: -16px;
}

.leave-half-morning-validated .row .col-12 {
  padding-top: 0px !important;
}
/* 
.calendar {
  font-family: "Roboto", sans-serif;
  max-width: 100%;
  margin: auto;
  overflow-x: auto;
} */

table {
  border-collapse: collapse;
  width: 100%;
  text-align: left;
}

th,
td {
  border: 1px solid #e0e0e0;
  padding: 8px 16px;
  text-align: center;
}

thead th {
  background-color: #f5f5f5;
  color: #757575;
}

tbody tr:nth-child(odd) {
  background-color: #fafafa;
}

tbody tr:nth-child(even) {
  background-color: #ffffff;
}

.on-leave {
  color: #f44336; /* Couleur rouge pour les jours de congé */
}

th:first-child,
td:first-child {
  text-align: left;
}

.leave-start-nok,
.leave-middle-nok,
.leave-end-nok {
  background-color: #fcc03c; /* Couleur pour les congés */
}

.leave-start-nok {
  border-right: none; /* Supprime la bordure droite */
}

.leave-end-nok {
  border-left: none; /* Supprime la bordure gauche */
}

.leave-middle-nok {
  border-left: none; /* Supprime les bordures gauche et droite */
  border-right: none;
}

/* ... Autres styles ... */

/* Style de base pour les cellules de congé */
.leave-start,
.leave-middle,
.leave-end {
  background-color: #001f47; /* Couleur pour les congés */
}

/* Bordures arrondies pour le début et la fin */
.leave-start {
  border-right: none; /* Supprime la bordure droite */
}

.leave-end {
  border-left: none; /* Supprime la bordure gauche */
}

/* Ajustements pour les cellules du milieu */
.leave-middle {
  border-left: none; /* Supprime les bordures gauche et droite */
  border-right: none;
}

/* Pour gérer les cas où le congé commence et se termine le même jour */

/* Style pour la dernière cellule de congé si elle est suivie d'une cellule non-congé */
.leave-end + td {
  border-left: 1px solid #e0e0e0; /* Remet la bordure gauche */
}

/* Style pour la première cellule normale après une série de congés */
td:not(.leave-middle):not(.leave-start):not(.leave-end) {
  border-left: 1px solid #e0e0e0; /* Assure que la cellule a une bordure gauche */
}
</style>
