<template>
  <div>
    <v-row>
      <v-col cols="12" md="2">
        <v-select
          v-model="yearFiltered"
          :items="years"
          label="Année"
          dense
          clearable
          item-text="text"
          item-value="year"
        ></v-select>
      </v-col>
      <v-col cols="12" md="2">
        <v-select
          label="Mois de l'année"
          v-model="monthFilter"
          dense
          :items="[
            {
              id: 0,
              name: 'Janvier',
            },
            {
              id: 1,
              name: 'Février',
            },
            {
              id: 2,
              name: 'Mars',
            },
            {
              id: 3,
              name: 'Avril',
            },
            {
              id: 4,
              name: 'Mai',
            },
            {
              id: 5,
              name: 'Juin',
            },
            {
              id: 6,
              name: 'Juillet',
            },
            {
              id: 7,
              name: 'Août',
            },
            {
              id: 8,
              name: 'Septembre',
            },
            {
              id: 9,
              name: 'Octobre',
            },
            {
              id: 10,
              name: 'Novembre',
            },
            {
              id: 11,
              name: 'Décembre',
            },
          ]"
          item-text="name"
          item-value="id"
        >
        </v-select>
      </v-col>
    </v-row>
    <v-data-table :items-per-page="-1" :items="socialStatements" :headers="headersSocialStatement" hide-default-footer>
      <template v-slot:no-data> Aucune donnée disponible </template>
      <template v-slot:item.actions="{ item }">
        <v-btn icon color="EoleError" target="_blank" @click="downloadFile(item.url)">
          <i class="fa-solid fa-file-pdf icon-medium"></i>
        </v-btn>

        <v-btn icon target="_blank" @click="openPreview(item)">
          <i class="fa-solid fa-eye icon-medium"></i>
        </v-btn>
      </template>
      <template v-slot:item.upload_datetime="{ item }">
        {{ getFormatDate(item.upload_datetime) }}
      </template>
    </v-data-table>
    <v-dialog v-model="dialogPreview" max-width="800px">
      <v-card>
        <v-card-text>
          <iframe :src="previewedUrl" frameborder="0" width="100%" height="500px"></iframe>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import config from "@/views/config/config"
import moment from "moment"
import FileSaver from "file-saver"
import { getCookie } from "@/utils/cookies"

export default {
  name: "OfficeSocialStatement",
  data() {
    return {
      previewedUrl: null,
      dialogPreview: false,
      selectedPreviewItem: {
        url: "",
      },
      previewSocialStatementDialog: false,
      yearFiltered: moment().year(),
      monthFilter: moment().month(),
      years: [],
      headersSocialStatement: [
        { text: "Nom", value: "name" },
        { text: "Date d'enregistrement", value: "upload_datetime" },
        { text: "Actions", value: "actions", sortable: false },
      ],
    }
  },
  props: {
    documents: {
      type: Array,
    },
  },
  methods: {
    async previewFile(path) {
      const idAccount = this.$store.state.user.id
      let url
      try {
        const response = await fetch(`${config.apiUri}/accounts/${idAccount}/documents/preview`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getCookie("jwt")}`,
          },
          body: JSON.stringify({
            url: path,
          }),
        })
        const data = await response.blob()
        url = URL.createObjectURL(data)
      } catch (error) {
        console.error(error)
      }

      return url
    },
    getFormatDate(date) {
      return moment(date).format("dddd Do MMMM YYYY [à] HH:mm")
    },
    async downloadFile(path) {
      const idAccount = this.$store.state.user.id
      try {
        const response = await fetch(`${config.apiUri}/accounts/${idAccount}/documents/preview`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getCookie("jwt")}`,
          },
          body: JSON.stringify({ url: path }),
        })

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`)
        }

        const data = await response.blob() // Récupère le blob du fichier
        const downloadUrl = URL.createObjectURL(data) // Crée une URL pour le blob

        // Créer un élément <a> pour déclencher le téléchargement
        const a = document.createElement("a")
        a.href = downloadUrl
        a.download = path.split("/").pop() // Utilise le nom du fichier dans l'URL ou définissez un nom spécifique
        document.body.appendChild(a)
        a.click()

        // Nettoyage: retire l'élément <a> et libère l'URL objet
        document.body.removeChild(a)
        window.URL.revokeObjectURL(downloadUrl)
      } catch (error) {
        console.error("Error downloading the file:", error)
      }
    },
    async openPreview(item) {
      this.selectedPreviewItem = item // Stocke l'item sélectionné
      this.previewedUrl = await this.previewFile(item.url) // Récupère l'URL du fichier à prévisualiser

      this.dialogPreview = true // Ouvre la boîte de dialogue
    },
    closePreview() {
      this.selectedPreviewItem = null // Réinitialise l'item sélectionné
    },
    isPreviewOpen(item) {
      return this.selectedPreviewItem && this.selectedPreviewItem.id === item.id
    },
  },
  computed: {
    socialStatements() {
      // Filtrer par année si yearFiltered est défini
      let filteredByYear = this.yearFiltered
        ? this.documents.filter(doc => doc.year == this.yearFiltered)
        : this.documents

      // Filtrer par mois si monthFilter est défini, en prenant en compte les mois indexés à partir de 0
      let filteredByMonth =
        this.monthFilter !== null
          ? filteredByYear.filter(doc => {
              // On s'attend à ce que monthFilter soit indexé à partir de 0, donc on l'ajuste ici si nécessaire
              return doc.months.some(month => month.month == this.monthFilter)
            })
          : filteredByYear

      // Transformer le format pour le v-data-table
      let transformed = []
      filteredByMonth.forEach(doc => {
        doc.months.forEach(month => {
          if (month.month == this.monthFilter) {
            month.files.forEach(file => {
              transformed.push({ ...file, year: doc.year, month: month.month })
            })
          }
        })
      })

      return transformed
    },
  },
  watch: {},

  async created() {
    //generer année de à année en cours
    let currentYear = new Date().getFullYear()
    for (let i = 2021; i <= currentYear; i++) {
      this.years.push({ year: i, text: i.toString() })
    }
  },
}
</script>

<style>
.import .v-btn--icon.v-size--default {
  height: 64px !important;
  width: 64px !important;
}
</style>
