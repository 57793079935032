<template>
  <div>
    <v-dialog v-model="invitSocial" max-width="1200px" :persistent="mailSending">
      <v-card class="rounded-l-xl" style="box-shadow: 0 0 60px -20px #000000d9">
        <v-card-text style="background-color: #f1f4f9">
          <v-row>
            <v-col cols="12" md="3" class="no-padding ml-n2">
              <v-card class="rounded-l-xl" color="#f1f4f9" style="background-color: #f1f4f9" flat>
                <v-card-title>
                  <v-img
                    :src="require('@/assets/images/logos/anavel_logo.png')"
                    max-height="150px"
                    max-width="120px"
                    alt="logo"
                    contain
                  ></v-img>
                </v-card-title>
                <v-card-title class="text-center justify-center muller-capitalized"
                  >Mes Services Assurance</v-card-title
                >
                <v-card-subtitle class="text-center justify-center">Un logiciel ANAVEL </v-card-subtitle>
                <v-card-text class="no-padding">
                  <v-img :src="require('@/assets/images/logos/layer.png')" alt="logo" width="300px" class=""> </v-img>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" md="9" class="no-padding d-flex">
              <v-card class="rounded-r-xl flex-fill flex d-flex flex-column" flat :loading="mailSending">
                <v-card-title>
                  <span class="text-h5">Inviter un membre</span>
                </v-card-title>
                <v-card-subtitle>
                  <span>Invitez votre cabinet social à rejoindre votre espace ANAVEL</span>
                </v-card-subtitle>
                <v-card-text class="pa-5">
                  <v-combobox
                    :disabled="mailSending"
                    label="Entrez vos adresses email"
                    v-model="emailInput"
                    placeholder="Email1;Email2;Email3;..."
                    chips
                    multiple
                    @keyup.enter="extractEmailList"
                    color="EoleBlue"
                  >
                  </v-combobox>
                </v-card-text>
                <v-spacer></v-spacer>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-spacer></v-spacer>
                  <v-btn text @click="sendInvitationMail" :loading="mailSending" :disabled="mailSending">Inviter</v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-row>
      <v-col cols="12" md="12" class="text-center EoleYellow--text">
        <v-switch label="Site unique" inset></v-switch>
      </v-col>
    </v-row>
    <v-row class="mt-n10">
      <v-col cols="12" md="12" class="text-center EoleYellow--text">
        <v-switch label="Congés" inset></v-switch>
      </v-col>
    </v-row>

    <div class="mt-n10">
      <v-row>
        <v-col cols="12" md="12" class="text-center EoleYellow--text">
          <v-switch label="Cabinet social" inset v-model="office.socialCabinet"></v-switch>
        </v-col>
      </v-row>

      <v-row class="mt-n13" v-if="itemsSocialCabinet.length > 0">
        <v-col cols="12" md="12" class="EoleYellow--text text-right">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-on="on" v-bind="attrs" color="EoleBlue" fab icon @click="openInvitation(4)">
                <i class="fa-solid fa-plus"></i>
              </v-btn>
            </template>
            <span>Inviter un membre du cabinet social</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-row v-if="itemsSocialCabinet.length > 0">
        <v-data-table
          :headers="headersSocialCabinet"
          :items="itemsSocialCabinet"
          hide-default-footer
          class="w-full"
          :key="1"
        >
          <template v-slot:item.partner="{ item }"> {{ item.lastname }} {{ item.firstname }} </template>
          <template v-slot:item.email="{ item }"> {{ item.email }} </template>
          <template v-slot:item.state="{ item }">
            <v-chip color="EoleGreen" small v-if="item.state === 1">Actif</v-chip>
            <v-chip color="EoleYellow" small v-else>Invitation en attente</v-chip>
          </template>
          <template v-slot:item.action="{ item }">
            <v-menu right transition="slide-x-transition" close-on-content-click>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on" style="" class="justify-center text-center">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item @click="importPutFile(item, 1, 'protection_sociale', 'conventions_collectives')">
                  <v-list-item-title>
                    <div><v-icon left>mdi-pencil</v-icon>Modifier</div>
                  </v-list-item-title>
                </v-list-item>

                <v-list-item color="EoleError" link @click="deleteSocial(item.id)" target="blank">
                  <v-list-item-title> <v-icon color="EoleError" left>mdi-delete</v-icon>Supprimer </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </v-row>
    </div>

    <div>
      {{ accountantCabinetStatus }}
      <v-row>
        <v-col cols="12" md="12" class="text-center EoleYellow--text">
          <v-switch label="Expert comptable" inset v-model="office.accountantCabinet"></v-switch>
        </v-col>
      </v-row>
      <v-row class="mt-n13" v-if="itemsAccountantCabinet.length > 0">
        <v-col cols="12" md="12" class="EoleYellow--text text-right">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-on="on" v-bind="attrs" color="EoleBlue" fab icon @click="openInvitation(3)">
                <i class="fa-solid fa-plus"></i>
              </v-btn>
            </template>
            <span>Inviter un membre du cabinet d'expert comptable</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-row v-if="itemsAccountantCabinet.length > 0">
        <v-data-table
          :key="2"
          :headers="headersSocialCabinet"
          :items="itemsAccountantCabinet"
          hide-default-footer
          class="w-full"
        >
          <template v-slot:item.partner="{ item }"> {{ item.lastname }} {{ item.firstname }}</template>
          <template v-slot:item.email="{ item }"> {{ item.email }} </template>
          <template v-slot:item.state="{ item }">
            <v-chip color="EoleGreen" small v-if="item.state === 1">Actif</v-chip>
            <v-chip color="EoleYellow" small v-else>Invitation en attente</v-chip>
          </template>
          <template v-slot:item.action="{ item }">
            <v-menu right transition="slide-x-transition" close-on-content-click>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on" style="" class="justify-center text-center">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item @click="importPutFile(item, 1, 'protection_sociale', 'conventions_collectives')">
                  <v-list-item-title>
                    <div><v-icon left>mdi-pencil</v-icon>Modifier</div>
                  </v-list-item-title>
                </v-list-item>

                <v-list-item color="EoleError" link @click="deleteSocial(item.id)" target="blank">
                  <v-list-item-title> <v-icon color="EoleError" left>mdi-delete</v-icon>Supprimer </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </v-row>
    </div>

    <div>
      {{ socialLawyerStatus }}
      <v-row>
        <v-col cols="12" md="12" class="text-center EoleYellow--text">
          <v-switch label="Avocat droit social" inset v-model="office.socialLawyer"></v-switch>
        </v-col>
      </v-row>

      <v-row class="mt-n13" v-if="itemsSocialLawyer.length > 0">
        <v-col cols="12" md="12" class="EoleYellow--text text-right">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-on="on" v-bind="attrs" color="EoleBlue" fab icon @click="openInvitation(8)">
                <i class="fa-solid fa-plus"></i>
              </v-btn>
            </template>
            <span>Inviter un membre du cabinet d'avocat social</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-row v-if="itemsSocialLawyer.length > 0">
        <v-data-table
          :headers="headersSocialCabinet"
          :items="itemsSocialLawyer"
          hide-default-footer
          class="w-full"
          :key="3"
        >
          <template v-slot:item.partner="{ item }"> {{ item.lastname }} {{ item.firstname }} </template>
          <template v-slot:item.email="{ item }"> {{ item.email }} </template>
          <template v-slot:item.state="{ item }">
            <v-chip color="EoleGreen" small v-if="item.state === 1">Actif</v-chip>
            <v-chip color="EoleYellow" small v-else>Invitation en attente</v-chip>
          </template>
          <template v-slot:item.action="{ item }">
            <v-menu right transition="slide-x-transition" close-on-content-click>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on" style="" class="justify-center text-center">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item @click="importPutFile(item, 1, 'protection_sociale', 'conventions_collectives')">
                  <v-list-item-title>
                    <div><v-icon left>mdi-pencil</v-icon>Modifier</div>
                  </v-list-item-title>
                </v-list-item>

                <v-list-item color="EoleError" link @click="deleteSocial(item.id)" target="blank">
                  <v-list-item-title> <v-icon color="EoleError" left>mdi-delete</v-icon>Supprimer </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </v-row>
    </div>
    <v-row>
      <v-col cols="12" md="12" class="text-center EoleYellow--text">
        <v-switch label="Avocat droit société" inset></v-switch>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import config from "@/views/config/config"
import emailjs from "@emailjs/browser"
import { init } from "@emailjs/browser"
import { getCookie } from "@/utils/cookies"
init("user_gUunL4KW2ImPaGngWHYhP")

export default {
  name: "OfficeSettings",
  data() {
    return {
      editedItem: {
        type: 0,
      },
      mailSending: false,
      invitSocial: false,
      config: config,
      emailInput: "",
      emailList: [],
      emailLoading: false,

      office: {
        uniqueSite: false,
        holidays: false,
        socialCabinet: false,
        accountantCabinet: false,
        socialLawyer: false,
        lawyerCompany: false,
      },
      headersSocialCabinet: [
        { text: "Partenaire(s)", value: "partner", width: "20%" },
        { text: "Email", value: "email", width: "30%" },
        { text: "Statut", value: "state", align: "center", width: "25%" },
        { text: "Action", value: "action", align: "center", width: "5%" },
      ],
      itemsSocialCabinet: [],
      itemsAccountantCabinet: [],
      itemsSocialLawyer: [],
    }
  },
  computed: {
    socialCabinetStatus() {
      if (this.office.socialCabinet === true) {
        this.itemsSocialCabinet.length === 0 ? this.openInvitation(4) : (this.invitSocial = false)
      }
    },
    accountantCabinetStatus() {
      if (this.office.accountantCabinet === true) {
        this.itemsAccountantCabinet.length === 0 ? this.openInvitation(3) : (this.invitSocial = false)
      }
    },

    socialLawyerStatus() {
      if (this.office.socialLawyer === true) {
        this.itemsSocialLawyer.length === 0 ? this.openInvitation(8) : (this.invitSocial = false)
      }
    },
  },
  watch: {},
  methods: {
    openInvitation(type) {
      this.invitSocial = true
      this.editedItem.type = type
    },
    async deleteSocial(id) {
      const idAgency = this.$store.state.user.agency_id
      try {
        await fetch(`${config.apiUri}/agencies/${idAgency}/socials/${id}`, {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${getCookie("jwt")}`,
          },
        }).then(response => {
          if (response.ok) {
            this.fetchSocialsAccount()
            this.$toast.success("Le membre du cabinet social a été supprimé avec succès", {
              position: "bottom-right",
            })
          } else {
            this.$toast.error("Une erreur est survenue", {
              position: "bottom-right",
            })
          }
        })
      } catch (e) {
        console.log(e)
      }
    },
    async fetchSocialsAccount() {
      this.itemsSocialCabinet = []
      this.itemsAccountantCabinet = []
      this.itemsSocialLawyer = []

      const idAgency = this.$store.state.user.agency_id

      try {
        const response = await fetch(`${config.apiUri}/agencies/${idAgency}/socials`, {
          mode: "cors",
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getCookie("jwt")}`,
          },
        })

        let data = await response.json()

        if (response.ok) {
          // this.itemsSocialCabinet = data.social
          if (!!data.social) {
            data.social.forEach(social => {
              this.itemsSocialCabinet.push({
                id: social.id,
                partner: social.lastname + " " + social.firstname,
                lastname: social.lastname,
                firstname: social.firstname,
                email: social.email,
                state: parseInt(social.state),
              })
            })
          }
          if (!!data.accountant) {
            data.accountant.forEach(accountant => {
              this.itemsAccountantCabinet.push({
                id: accountant.id,
                partner: accountant.lastname + " " + accountant.firstname,
                lastname: accountant.lastname,
                firstname: accountant.firstname,
                email: accountant.email,
                state: parseInt(accountant.state),
              })
            })
          }

          if (!!data.manager_due) {
            data.manager_due.forEach(manager => {
              this.itemsSocialLawyer.push({
                id: manager.id,
                partner: manager.lastname + " " + manager.firstname,
                lastname: manager.lastname,
                firstname: manager.firstname,
                email: manager.email,
                state: parseInt(manager.state),
              })
            })
          }

          this.itemsSocialCabinet.length > 0 ? (this.office.socialCabinet = true) : (this.office.socialCabinet = false)
          this.itemsAccountantCabinet.length > 0
            ? (this.office.accountantCabinet = true)
            : (this.office.accountantCabinet = false)

          this.itemsSocialLawyer.length > 0 ? (this.office.socialLawyer = true) : (this.office.socialLawyer = false)
        } else {
          throw new Error("Une erreur est survenue")
        }
      } catch (e) {
        console.log(e)
      }
    },
    verifyEmail(email) {
      return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
    },
    extractEmailList() {
      const emailList = [] // Créez une nouvelle liste pour stocker les adresses e-mail.

      this.emailInput.forEach(element => {
        const matches = element.match(/<([^>]+)>/g) // Utilisez /g pour correspondre à toutes les occurrences dans la chaîne.
        if (matches) {
          matches.forEach(match => {
            const email = match.slice(1, -1) // Supprimez les chevrons autour de l'e-mail.
            emailList.push(email)
          })
        } else {
          if (this.verifyEmail(element)) {
            emailList.push(element)
          } else {
            this.$toast.error("L'adresse email " + element + " n'est pas valide", {
              position: "bottom-right",
            })
          }
        }
      })

      this.emailList = emailList
      this.emailInput = this.emailList
    },
    async sendInvitationMail() {
      const idAgency = this.$store.state.user.agency_id
      console.log(this.emailList)
      this.mailSending = true
      async function postGuestAccount(email, type) {
        console.log("launch postGuestAccount")
        try {
          const response = await fetch(`${config.apiUri}/agencies/${idAgency}/socials`, {
            method: "POST",
            body: JSON.stringify({
              email: email,
              account_state_id: type,
            }),
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getCookie("jwt")}`,
            },
          })

          let data = await response.json()

          if (response.ok) {
            return { idAccount: data.id_social, token: data.token }
          } else {
            throw new Error("Une erreur est survenue")
          }
        } catch (e) {
          console.log(e)
        }
      }

      for (const collaborator of this.emailList) {
        this.emailLoading = true
        await new Promise(resolve => setTimeout(resolve, 1000))

        await postGuestAccount(collaborator, this.editedItem.type)
      }
      this.fetchSocialsAccount()
      this.mailSending = false
      this.invitSocial = false
      this.editedItem.type = 0
      this.emailList = []
      this.emailInput = ""
    },
  },

  created() {
    this.fetchSocialsAccount()
    console.log(this.$store.state.user)
  },
}
</script>

<style></style>
